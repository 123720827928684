import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
// let originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
//
// originalPush = VueRouter.replace;
// VueRouter.replace = function replace(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
  mode: 'history',  //去掉url中的#
  routes: [

    {
      path: '/',
      name: 'home',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Home.vue')
    },
    {
      path: '/view',
      name: 'view',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/View.vue')
    },
    {
      path: '/intro',
      name: 'intro',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Intro.vue')
    },
    {
      path: '/project',
      name: 'project',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Project.vue')
    },
    {
      path: '/_login',
      name: 'login',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Login.vue')
    },
    {
      path: '/Pay',
      name: 'Pay',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Pay.vue')
    },
    {
      path: '/api_demo',
      name: 'api_demo',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/ApiDemo.vue')
    },
    {
      path: '/manuscript',
      name: 'manuscript',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/manuscript/ViewManuscript.vue')
    },
    {
      path: '/24review',
      name: '24review',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/24review/ViewManuscript.vue')
    },
    {
      path: '/sub',
      name: 'sub',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/jcar/Sub.vue')
    },
  ],
  // routes
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
