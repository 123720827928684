<template>
  <div id="app">
    <NotGoogleTip></NotGoogleTip>
    <globalTip :canClose="canClose" :topTip="topTip" :open="tipOpen" :okBnText="tipOkBnText" v-show="tipOpen" :tipText="tipText" :tipType="tipType" @okClose="closeCallBack" @okClick="okCallBack" ></globalTip>

    <router-view/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

import { mapState, mapActions,mapGetters } from 'vuex'
import globalTip from '@components/GlobalTip'
import $ from 'jquery'
import api from './api/index'
import utils from "./api/common/utils";
import NotGoogleTip from "./components/NotGoogleTip";
import commonUtils from "../common/commonUtils";
import loginApi from "./api/loginApi";

export default {
  name: 'App',
  components:{
    globalTip,NotGoogleTip
  },
  data(){
    return {
      tipOpen:false,
      tipText: '',
      tipType:'s',

      closeBnCallBack:null,
      okBnCallBack:null,
      onCloseCallBack:null,

      tipOkBnText:'确定' ,
      canClose:true,
      loading:null,
      topTip:'提示',
    }
  },
  methods:{
    ...mapActions(
        {
          setUserInfoVo:'sciStore/setUserInfoVo',
          setMyCliNum:'sciStore/setMyCliNum',
          setUserInfo:'sciStore/setUserInfo',
          setNavAcrossState:'sciStore/setNavAcrossState',
          setNavSonKeyCount:'sciStore/setNavSonKeyCount',
          setparentNav:'sciStore/setparentNav',
          setCliDataObj:'sciStore/setCliDataObj',
          setMyCliShow:'sciStore/setMyCliShow',
          setMyCliDataResetCount:'sciStore/setMyCliDataResetCount',
          setShowLogin:'sciStore/setShowLogin',
          setShowType:'sciStore/setShowType',
          setRepullMyGeoDataCount:'sciStore/setRepullMyGeoDataCount'

        }),
    onGotoPageClick(path,data){

      window.globalMethods.openLoad()
      setTimeout(() => {
        if(data){
          console.log('传递参数',data)
          this.$router.push({name:path,params:data})
        }else{
          this.$router.push({path:path})
        }

      } ,100)

      setTimeout(() => {
        window.globalMethods.closeLoad()
      } ,100)

    },

    closeCallBack(){
      if(this.closeBnCallBack){
        this.closeBnCallBack();
      }

      if( window.globalMethods.swichTip){
        window.globalMethods.swichTip(false)
      }

    },
    okCallBack(){

      if(this.okBnCallBack){
        this.okBnCallBack()
      }
      if( window.globalMethods.swichTip){
        window.globalMethods.swichTip(false)
      }
    },

    /*
    * state  布尔类型 true为显示提示
    * type 字符串类型 s 成功 e 错误
    * onCloseCallBack 当提示整个关闭的时候的回调
    * okBnCallBack 点击了确认按钮的回调
    * closeBnCallBack 点击了右上角x按钮后的回调
    * tipOkBnText 确认按钮的文字
    * */
    onChangeTipStateClick(state,type,tipText,onCloseCallBack,okBnCallBack,closeBnCallBack,tipOkBnText,canClose,topTip){

      //debugger
      if(!commonUtils.varIsNull(canClose)){
        this.canClose=canClose
      }
      if(!commonUtils.varIsNull(topTip)){
        this.topTip=topTip
      }else{
        this.topTip='提示'
      }


      if(tipOkBnText){
        this.tipOkBnText=tipOkBnText
      }

      if (state){
        //开启提示
        this.tipType=type
        this.tipText=tipText


        if (onCloseCallBack){
          this.onCloseCallBack=onCloseCallBack
        }else{
          this.onCloseCallBack=null
        }
        if (closeBnCallBack){

          this.closeBnCallBack=closeBnCallBack
        }else{
          this.closeBnCallBack=null
        }

        if (okBnCallBack){

          this.okBnCallBack=okBnCallBack
        }else{
          this.okBnCallBack=null
        }


      }else{
        //关闭提示
        this.tipOkBnText='确认'
        this.tipType='s'
        this.tipText=''

        /* if ($('.el-dialog__wrapper')) {
           $('.el-dialog__wrapper').show()
         }
         if ($('.el-dialog--center')) {
           $('.el-dialog--center').show()
         }
         if ($('.v-modal')) {
           $('.v-modal').show()
         }*/

      }

      if (this.tipOpen!=state){
        this.tipOpen=state

        if ( (!state) && this.onCloseCallBack ) {
          this.onCloseCallBack()
        }
      }





    }
  },
  beforeCreate() {

    let vue= this
    let load=null



    let isScroll=false
    window.globalVals={
      vue,load,isScroll,$
    }


    window.globalMethods={

      openLoad(){
        // window.globalVals.load = window.globalVals.vue.$loading({
        //   //lock: true,   //锁定全屏  当滑动条出现时 开启 会导致滑动条在有遮罩的时候消失  布局会移动
        //   text: '数据正在分析中,请耐心等待...',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0)'
        // })

        window.globalVals.isScroll=window.globalMethods.isWindowScroll()
      },
      closeLoad(){

        if (window.globalVals.load) {
          window.globalVals.load.close()
        }

        window.globalVals.isScroll=window.globalMethods.isWindowScroll()
      },
      isWindowScroll() {

        let flag=true
        //console.log(window.globalVals.$(document).outerHeight(true),window.globalVals.$(window).outerHeight(true))
        if( window.globalVals.$(document).outerHeight(true)-5>window.globalVals.$(window).outerHeight(true) ){
          // alert("有滚动条");
        }else{
          flag=false
        }
        window.globalVals.isScroll=flag
        return flag
      }

    }


    window.globalMethods.openLoad()

  }
  ,mounted() {

    // commonUtils.getUrlTK(this)
    //     .then((tk)=>{
    //       tk=commonUtils.urlDeCode(tk)
    //       commonUtils.setCookie("tk",tk,1,1)
    //
    //       loginApi.getUsername(()=>{},null,false,tk)
    //     })




    if(!api.config.debug){
      //关闭输出
      window.console['log']=function () {

      }
    }



    let that=this

    /*
* state  布尔类型 true为显示提示
* type 字符串类型 s 成功 e 错误
* onCloseCallBack 当提示整个关闭的时候的回调
* okBnCallBack 点击了确认按钮的回调
* closeBnCallBack 点击了右上角x按钮后的回调
* tipOkBnText 确认按钮的文字
* */
    let swichTip=  function (state,type,tipText,onCloseCallBack,okBnCallBack,closeBnCallBack,tipOkBnText,canClose,topTip){

      that.onChangeTipStateClick(state,type,tipText,onCloseCallBack,okBnCallBack,closeBnCallBack,tipOkBnText,canClose,topTip)

    }

    let gotoPage=function(path){
      that.onGotoPageClick(path)
    }


    let setUserInfoVo=(userInfoVo)=>{
      this.setUserInfoVo({userInfoVo:userInfoVo})
    }

    let setMyCliNum=(myCliNum)=>{
      this.setMyCliNum({myCliNum:myCliNum})
    }
    let setCliDataObj=(myCliDataObj)=>{
      this.setCliDataObj(myCliDataObj)
    }

    let setRepullMyGeoDataCount=()=>{

      this.setRepullMyGeoDataCount()
    }

    window.globalMethods['swichTip']=swichTip
    window.globalMethods['gotoPage']=gotoPage


    window.globalMethods['setUserInfoVo']=setUserInfoVo

    window.globalMethods['setMyCliNum']=setMyCliNum
    window.globalMethods['setRepullMyGeoDataCount']=setRepullMyGeoDataCount



    window.globalMethods.closeLoad()



    function windowSizeChange(){

      window.globalVals.isScroll=window.globalMethods.isWindowScroll()
      let len=window.globalVals.vue.$children.length
      for (let i=0;i<len;i++){
        window.globalVals.vue.$children[i].scroll=window.globalVals.isScroll
      }


      // console.log('窗口改变',window.globalVals.vue,window.globalVals.isScroll,window.globalMethods.isWindowScroll())
    }
    window.globalMethods['windowSizeChange']=windowSizeChange;
    utils.autoRemFontSize()

    $(window).resize(function () {

      windowSizeChange()


      // $('html').css('font-size',(utils.getWindowWidth()*1.0/50)+'px')
      let width=utils.getWindowWidth()
      console.log(width,'宽度')


      let fontSize=$('html').css('font-size').substr(0,$('html').css('font-size').length-2)*1;

      utils.autoRemFontSize()


    })



  }
}
</script>

/*body{*/
/*  margin:0em;*/
/*}*/
/*#app {*/
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  !*margin-top: 60px;*!*/
/*}*/


<style lang="less">
@import "assets/css/base";

//@import "assets/css/const";

body{
  margin:0em;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

</style>

