import md5 from 'js-md5';
let Base64 = require('js-base64').Base64;
// import math from 'mathjs'
let math = require('mathjs');
import $ from 'jquery'
import productInfo from "./productInfo";

//字符串转字节序列
function stringToByte(str){
    let bytes = new Array();
    let len, c;
    len = str.length;
    for(let i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if(c >= 0x010000 && c <= 0x10FFFF) {
            bytes.push(((c >> 18) & 0x07) | 0xF0);
            bytes.push(((c >> 12) & 0x3F) | 0x80);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if(c >= 0x000800 && c <= 0x00FFFF) {
            bytes.push(((c >> 12) & 0x0F) | 0xE0);
            bytes.push(((c >> 6) & 0x3F) | 0x80);
            bytes.push((c & 0x3F) | 0x80);
        } else if(c >= 0x000080 && c <= 0x0007FF) {
            bytes.push(((c >> 6) & 0x1F) | 0xC0);
            bytes.push((c & 0x3F) | 0x80);
        } else {
            bytes.push(c & 0xFF);
        }
    }
    return bytes;


}

//字节序列转ASCII码
//[0x24, 0x26, 0x28, 0x2A] ==> "$&C*"
function byteToString(arr) {
    if(typeof arr === 'string') {
        return arr;
    }

    let str = '',
        _arr = arr;
    for(let i = 0; i < _arr.length; i++) {
        let one = _arr[i].toString(2),
            v = one.match(/^1+?(?=0)/);
        if(v && one.length == 8) {
            let bytesLength = v[0].length;
            let store = _arr[i].toString(2).slice(7 - bytesLength);
            for(let st = 1; st < bytesLength; st++) {
                store += _arr[st + i].toString(2).slice(2);
            }
            str += String.fromCharCode(parseInt(store, 2));
            i += bytesLength - 1;
        } else {
            str += String.fromCharCode(_arr[i]);
        }
    }
    return str;
}




function  restResultDecode(str){

    try {
        str=JSON.parse(str)
    }catch (e) {

    }

    str=Base64.decode(str);
    //第一组
    let bytes=stringToByte(str);
    bytes=bytes.map(b=>{
        return b + 3;
    });
    str=byteToString(bytes);
    str=Base64.decode(str);

    //第二组

    bytes=stringToByte(str);
    for(let i=0;i<bytes.length-1;i+=2){
        bytes[i]^=bytes[i+1]
    }
    str=byteToString(bytes);
    str=Base64.decode(str);


    //第三组
    bytes=stringToByte(str);
    bytes=bytes.map(b=>{
        return b + 5;
    });
    str=byteToString(bytes);
    str=Base64.decode(str);

    try {
        str=JSON.parse(str);
    }catch (e) {
        try {
            str='"'+str+'"';
            str=JSON.parse(str);
        }catch (e) {
            console.log(e)
        }
    }


    console.log('统一处理结果',str)
    return str;
}

function getCookie(c_name,notEsc){

    if (document.cookie.length>0){

        let c_start=document.cookie.indexOf(c_name + "=")
        if (c_start!=-1){
            c_start=c_start + c_name.length+1
            let c_end=document.cookie.indexOf(";",c_start)
            if (c_end==-1) c_end=document.cookie.length
            if(varNotNull(notEsc)){
                return document.cookie.substring(c_start,c_end)
            }else{
                return unescape(document.cookie.substring(c_start,c_end))
            }

        }
    }
    return ""
}

//设置cookie,增加到vue实例方便全局调用
function setCookie (c_name, value, expiredays,notEsc) {

    var exdate = new Date();
    delCookie(c_name)
    exdate.setDate(exdate.getDate() + expiredays);
    if(varNotNull(notEsc)){
        document.cookie = c_name + "=" + value + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    }else{
        document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    }

};

//删除cookie
 function delCookie (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (varNotNull(cval))
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

function formatDate(d,fmt) {
    let o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "H+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        "S": d.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function formatDateTime(d){

    if(!d){
        return ""
    }

    return formatDate(d,'yyyy-MM-dd HH:mm:ss')
}

/*'yyyy-MM-dd HH:mm:ss'格式的字符串转日期*/

function stringToDate(str){

    let tempStrs = str.split(" ");

    let dateStrs = tempStrs[0].split("-");

    let year = parseInt(dateStrs[0], 10);

    let month = parseInt(dateStrs[1], 10) - 1;

    let day = parseInt(dateStrs[2], 10);

    let timeStrs = tempStrs[1].split(":");

    let hour = parseInt(timeStrs [0], 10);

    let minute = parseInt(timeStrs[1], 10);

    let second = parseInt(timeStrs[2], 10);

    let date = new Date(year, month, day, hour, minute, second);

    return date;

}

function deleteCookie(name) {

    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = getCookie(name);

    if (cval != null)
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();

}

const md5Count=3
function md5Text(pass) {

        pass=md5(pass)

    return pass;
}

function md5PassWd(pass) {
    for(let i=0;i<md5Count;i++){
        pass=md5(pass)
    }
    return pass;
}


//求数组2 在 数组1 下的差集
function subSet( arr1, arr2) {
    let set1 = arr1.concat();
    let set2 = arr2.concat();

    let subset = [];

    for (let item of set1) {
        //数组2的每一个id 都不在 数组1  那么是差集部分
        if (set2.every(k => item.id != k.id)) {
            subset.push(item);
        }

    }

    return subset;
}

function varNotNull(v) {

    if(typeof(v) === "undefined"){
        return false
    }
    if(v===null){
        return false
    }
    if(v===""){
        return false
    }
    return true;
}

function objNotNull(obj) {
    let res=varNotNull(obj)
    if(res){
        if(JSON.stringify(obj) == "{}"){
            return false
        }
        return true
    }
    return false;
}


function getFileEx(fileName) {
    let fileExtension="";
    try {
        fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    }catch (e) {
        return  fileExtension;
    }

    return fileExtension
}
function splitFileName(text) {
    let pattern = /\.{1}[a-z]{1,}$/;
    if (pattern.exec(text) !== null) {
        return (text.slice(0, pattern.exec(text).index));
    } else {
        return text;
    }
}

function varIsNull(v) {
    return !varNotNull(v);
}

function cpObj(origin) {
    let _obj = JSON.stringify(origin);
    let  objClone = JSON.parse(_obj);
    return objClone;
}

function matrixT(doubleList) {
    // let m = math.matrix([[1, 2], [3, 4]])

    try {
        let max=0;
        for(let i=0;i<doubleList.length;i++){
            let list=doubleList[i]
            if(list.length>=max){
                max=list.length
            }
        }

        for(let i=0;i<doubleList.length;i++){
            let list=doubleList[i]
            if(list.length<max){
                let remain=max-list.length
                while (remain>0){
                    try {
                        list.push("")
                    }catch (e) {

                    }finally {
                        remain--
                    }


                }
            }
        }
    }catch (e) {

    }


    return  math.transpose(doubleList)
}

function isString(obj) {
    return typeof(obj)=='string'
}
function listUniqByfiled(arr1,field) {
    const res = new Map();
    return arr1.filter((a) => !res.has(a[field]) && res.set(a[field], 1))
}
function listUniqFiled(array,f){
    let temp = [];
    let index = [];
    let l = array.length;
    for(let i = 0; i < l; i++) {
        for(let j = i + 1; j < l; j++){
            if (array[i][f] === array[j][f]){
                i++;
                j = i;
            }
        }
        temp.push(array[i]);
        index.push(i);
    }
    return temp;
}

function listUniqMd5(array){
    array=cpObj(array)
    let temp = [];
    let index = [];
    let l = array.length;
    for(let i = 0; i < l; i++) {
        for(let j = i + 1; j < l; j++){
            if (md5Text(JSON.stringify(array[i])) === md5Text(JSON.stringify(array[j]))){
                i++;
                j = i;
            }
        }
        temp.push(array[i]);
        index.push(i);
    }
    return temp;
}


function listUniq(array){
    let temp = [];
    let index = [];
    let l = array.length;
    for(let i = 0; i < l; i++) {
        for(let j = i + 1; j < l; j++){
            if (array[i] === array[j]){
                i++;
                j = i;
            }
        }
        temp.push(array[i]);
        index.push(i);
    }
    return temp;
}

function fillLists(lists,fillVal) {
    try {
        let lens=[]
        for(let i=0;i<lists.length;i++){
            let list=lists[i]
            lens.push(list.length)
        }
        let max=Math.max(...lens)

        for(let i=0;i<lists.length;i++){
            let list=lists[i]
            let len=list.length;
            let diff=max-len
            while(diff>0){
                list.push(fillVal)
                diff--;
            }
        }

    }catch (e) {
        return lists;
    }
    return lists;
}




function hasChinese(str) {
    if(/.*[\u4e00-\u9fa5]+.*$/.test(str))
    {
        return true;
    }
    return false;
}
function hasEnglish(str) {
    if(/.*[a-zA-Z]+.*$/.test(str))
    {
        return true;
    }
    return false;
}

function onlyChinese(str) {

    if(hasEnglish(str)){
        return false
    }
    return hasChinese(str)
}



function moreThanBytesLimitAndTtim(val,limit) {
    let len = 0;
    let res=''

    for (let i = 0; i < val.length; i++) {
        let a = val.charAt(i);
        if (a.match(/[^\x00-\xff]/ig) != null) {
            len += 2;
        }
        else {
            len += 1;
        }
        if(len<=limit){
            res+=a
        }else{
            break
        }
    }


    return res;
}


function getByteLen(val) {
    let len = 0;
    for (let i = 0; i < val.length; i++) {
        let a = val.charAt(i);
        if (a.match(/[^\x00-\xff]/ig) != null) {
            len += 2;
        }
        else {
            len += 1;
        }
    }
    return len;
}






function preloadImg(url,success,error) {
    let img = new Image();
    img.src = url;
    console.log('pre')
    let errorFlag=false;
    if(img.complete) {
        //接下来可以使用图片了
        //do something here
        if(varNotNull(success)){
            errorFlag=true;
            success(img);
        }
        console.log('pre complete')
    }
    else {
        img.onload = function() {
            //接下来可以使用图片了
            //do something here
            errorFlag=true;
            if(varNotNull(success)){
                success(img);
            }
            console.log('pre onload')
        };
    }

    img.onerror=()=>{
        if(varNotNull(error)){
            error();
        }

    }

}






function getBrowser() {
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) { //判断是否Opera浏览器
        return "Opera"
    }
    if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
        return "FF";
    }

    // if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
    //   return "Safari";
    // }

    if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
        return "IE";
    }
    if (userAgent.indexOf("Edge") > -1) { //判断是否Safari浏览器
        return "Edge";
    }
//
    if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    }
}
function isGoogle() {
    if(getBrowser()==='Chrome'){
        return true;
    }
    return  false;
}

function searchMainPermissionCheck(index,userInfo) {
    //searchFileds:['title','keyword','abstract','introduction','methods','results','discussion','img','review','report','acknowleg','academic']

    if( userInfo.maxRole && userInfo.maxRole.weight>3){
        //年费 所有权限
        return true
    }

    if( userInfo.maxRole.weight===3){
        //月费 所有权限
        if(index>=0 && index<=7){
            return true
        }else{
            return false;
        }
    }
    //剩余是免费用户的 不是新用户

    if(index>=0 && index<=6){
        return true
    }else{
        return false;
    }
}
function otherSearchPermittionCheck(field,userInfo) {

    if( userInfo.maxRole && userInfo.maxRole.weight>3){
        //年费 所有权限
        return true
    }

    if( userInfo.maxRole.weight===3){
        //旧月费 所有权限
        return true;
    }

    return false;
    // switch (field) {
    //   case 'img':
    //     break;
    //   case 'review':
    //     break;
    //   case 'report':
    //     break;
    // }
}




function getSelfHeight(){

    let hs=[]
    let addh=0

    if(varNotNull(  $(".footer"))  && varNotNull($(".page-warp"))){
        let footerh=$(".footer").height();
        addh+=footerh+ $('.page-warp').height();

    }
    hs.push(
        $(window).height(),
        $('body').height(),
        $('.page-warp').height(),
        $('.main').height(),
        $('.page').height(),
        $('.paper-main').height(),
        addh
    )

    hs=hs.filter( h=> !isNaN(h) )

    hs= hs.filter(item=>{
        return  varNotNull(item)
    })
    let max = Math.max.apply(null,hs);

    // console.log(hs.length+"个高度数据中，最大值"+max+"==="+ ($('.paper-main').height()))

    return max

}
function getSelfWidth(){

    let hs=[]
    hs.push(
        $(window).width(),
        $('body').width(),
        $('.page-warp').width(),
        $('.main').width(),
        $('.page').width(),
    )

    hs=hs.filter( h=> !isNaN(h) )
    hs= hs.filter(item=>{
        return  varNotNull(item)
    })

    let max = Math.max.apply(null,hs);

    // console.log(hs.length+"个宽度数据中，最大值"+max)

    return max

}


function getProductByPath(path) {
    console.log(productInfo.HR_PRODUCT_GROUPS)

    for(let i=0;i<productInfo.HR_PRODUCT_GROUPS.length;i++){
        let goups=productInfo.HR_PRODUCT_GROUPS[i]
        for(let j=0;j<goups.length;j++){
            let pro=goups[j]
            if(pro.productLogoClickUrl==path){
                return pro
            }
        }
    }


    return null
}

function checkUserCanUseProduct(userInfo,productPath) {

    let product=getProductByPath(productPath)
    return new Promise((resolve, reject) => {
        if(userInfo['maxRole']['weight']<product.weight){
            reject(product)
        }else{
            resolve(product)
        }
    })
}



function setLocalStorage(key,data) {

    if(!varNotNull(data) || !varNotNull(key)){
        return
    }


    if(typeof(data) != "string"){
        data=JSON.stringify(data);
    }

    localStorage.setItem(key,data);
}

function getLocalStorage(key) {
    if( !varNotNull(key)){
        return null;
    }
    let data=localStorage.getItem(key);
    if(!varNotNull(data)){
        return null;
    }

    if( (data.startsWith("{") && data.endsWith("}"))   || (data.startsWith("[") && data.endsWith("]")) ){
        data=JSON.parse(data);
        return  data;
    }
    return data;
}

function deleteLocalStorage(key) {
    if( !varNotNull(key)){
        return null;
    }
    localStorage.removeItem(key);
}

function getValFromObj(obj,path,splitFlag) {

    if(varIsNull(splitFlag)){
        splitFlag='.'
    }


    let res=null;

    try {
        let resData=obj;
        let parts=path.split(splitFlag);
        for(let i=0;i<parts.length;i++){
            let v=resData[parts[i]]
            resData=v;
        }
        res=resData
    }catch (e) {
        return null
    }
    return res;
}

function listSort(list,field,cpList,isDesc) {
    if (varIsNull(cpList)){
        cpList=true
    }
    if (varIsNull(isDesc)){
        isDesc=true
    }
    //
    if (varIsNull(list)){
        return  list;
    }

    if (cpList){
        list=cpObj(list)
    }


    function sortFc(a,b){
        if(isDesc){
            if(field==null||field==''){
                return b - a;
            }
            return  getValFromObj(b,field) - getValFromObj(a,field)
        }else{
            if(field==null||field==''){
                return a - b;
            }
            return    getValFromObj(a,field) - getValFromObj(b,field)
        }

    }

    list.sort(sortFc);

    return list

}


function listSortDesc(list,field,cpList) {
   return  listSort(list,field,cpList,true)
}

function listSortAes(list,field,cpList) {
    return  listSort(list,field,cpList,false)
}

function scrollTop() {
    scrollTo(0,0);
}

function splitArrayAvgNum(datas,num){


    let result = [];
    for(let i=0;i<datas.length;i+=num){
        try {
            result.push(datas.slice(i,i+num));
        }catch (e){
            console.log(e)
        }

    }
    return result
}

function isArry(vals){

    if(varIsNull(vals)){
        return false;
    }

    return  Array.isArray(vals);

}


function isFullNewUser(userInfo){

    if(getValFromObj(userInfo,'username')==null){
        return  true
    }
    try {
        if( (userInfo && userInfo.username && userInfo.maxRole.weight===5  && userInfo.usageCount==1) ||
            (userInfo && userInfo.username && userInfo.maxRole.weight===5  && varIsNull(userInfo.usageCount)) ) {
            return true;
        }
    }catch (e) {

    }
    return false
}

function isNewUserOrHonor(userInfo){

    if(getValFromObj(userInfo,'username')==null){
        return  false
    }
    try {
        if( (userInfo && userInfo.username && userInfo.maxRole.flag==='new_user' ) ||
            (userInfo && userInfo.username && userInfo.maxRole.flag==='vip_honor'  ) ) {
            return true;
        }
    }catch (e) {

    }
    return false
}

function charListSort(list){

    let lens=list.map(item=>{
         return item.length
    })
    let min=Math.min(...lens)


    let countMap={}
    let diffPos=-1;


    for(let i=0;i<list.length;i++){

        if(diffPos!=-1){
            break
        }

        let str=list[i]


        for(let j=0;j<min;j++){

            if(diffPos!=-1){
                break
            }

            let c=str.charAt(j)+""
            if(j in countMap){
                let bc=countMap[j]
                if(bc!=c){
                    diffPos=j
                    break
                }else{

                }
            }else{
                countMap[j]=c;
            }

        }

    }


    list=list.sort((a,b)=>{

        if(diffPos!=-1){
            return  a.charCodeAt(diffPos)-b.charCodeAt(diffPos)
        }else{
            return  a.charCodeAt(0)-b.charCodeAt(0)
        }


    })


    return list
}


function checkUserNameReg(userName){
    //用户名正则，4到16位（字母，数字，下划线，减号）
    let uPattern = /^[a-zA-Z0-9_-]{4,16}$/;
    //输出 true
    return uPattern.test(userName)
}

function checkUserPassReg(pass){
    //密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
    let pPattern = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,20}$/;
    //输出 true
    return pPattern.test(pass)
}

function getUrlTK(that){
    let tk=''
    try {
        let t= that.$route.query.t
        if(varNotNull(t)){
            tk=t
        }
    }catch (e) {

    }

    return new Promise((resolve, reject) => {
        if(varNotNull(tk)){
            resolve(tk)
        }else{
            reject()
        }

    });
}


function urlEnCode(data){
   return  encodeURIComponent(data)
}

function urlDeCode(data){
    return  decodeURIComponent(data)
}
function debounce(fn,delay){
    let timer = null //借助闭包
    return ()=> {

        if(timer){
            clearTimeout(timer)
        }
        timer = setTimeOut(fn,delay)

    }
}
function shortShow(text,len){
    return text&&text.length>len?text.substring(0,len)+"..." : text;
}

function getCurDate(){
    var date = new Date()
    return date.getFullYear()+""+(date.getMonth()+1)+""+date.getDate();
}

function getZhNameOrEnName(zhName,enName){
    if(!zhName){
        return enName
    }
    var reg = RegExp(/[嘿]/);
    if(zhName.match(reg)){
        return enName
    }
    if((zhName.length==2||zhName.length==3)){
        var reg = RegExp(/[,，、.。]/);
        if(zhName.match(reg)){
            return enName
        }else{
            return zhName
        }
    }else{
        return enName
    }
}

function isZhName(zhName){
    if(!zhName){
        return false
    }
    if((zhName.length==2||zhName.length==3)){
        var reg = RegExp(/[,，、.。]/);
        if(zhName.match(reg)){
            return false
        }else{
            return true
        }
    }else{
        return false
    }
}

function trimTag(text){
    if(!text)return text
    var regex = /(<([^>]+)>)/ig
    return text.replace(regex, "");
}

function trimDate(reqTime){
    let reqDateTime=new Date(reqTime).getTime()
    let nowDateTime=new Date().getTime()
    let diffTime=nowDateTime-reqDateTime
    if(diffTime<2000){
        return "1秒前"
    }
    let diffSec=(diffTime/1000).toFixed(0)
    if(diffSec<60){
        return diffSec+"秒前"
    }else if(diffSec<60*60){
        let diffMin=diffSec/60
        diffMin=diffMin.toFixed(0)
        return diffMin+"分钟前"
    }else if(diffSec<60*60*24){
        let diffHour=diffSec/(60*60)
        diffHour=diffHour.toFixed(0)
        return diffHour+"小时前"
    }else if(diffSec<60*60*24*7){
        let diffDay=diffSec/(60*60*24)
        diffDay=diffDay.toFixed(0)
        if(diffDay>1){
            diffDay=diffDay-1
        }
        return diffDay+"天前"
    }else if(diffSec<60*60*24*30){
        let diffWeek=diffSec/(60*60*24*7)
        diffWeek=diffWeek.toFixed(0)
        return diffWeek+"周前"
    }else if(diffSec<60*60*24*30*12){
        let diffMon=diffSec/(60*60*24*30)
        diffMon=diffMon.toFixed(0)
        return diffMon+"月前"
    }else{
        let diffYear=diffSec/(60*60*24*30*12)
        diffYear=diffYear.toFixed(0)
        return diffYear+"年前"
    }
}

//数组取差集
function getDiffArr(arr1, arr2) {
    var set1 = new Set(arr1);
    var set2 = new Set(arr2);
    var subset = [];
    for (let item of set1) {
        if (!set2.has(item)) {
            subset.push(item);
        }
    }
    return subset;
}

function getMin(arr){
    return arr.slice(0).sort(function(a,b){return a-b})[0];
}

export default {
    listUniqMd5,shortShow,getCurDate,getZhNameOrEnName,trimTag,trimDate,isZhName,getDiffArr,getMin,
    Base64,stringToByte,byteToString,restResultDecode,
    scrollEleTargetRight(select,targetSelect){
        let ele=document.querySelector(select)

        ele.scrollLeft = ele.offsetWidth;
    },
    scrollEleTargetTop(select,targetSelect){

        let ele=document.querySelector(select)
        let target=document.querySelector(targetSelect)


        ele.scrollTop = target.offsetTop;
    },
    scrollTarget(id){
        document.querySelector(id).scrollIntoView(true);
    },  deleteCookie,getCookie,md5PassWd,subSet,formatDateTime,stringToDate,
    varNotNull,objNotNull,getFileEx,splitFileName,varIsNull,listUniq,cpObj,matrixT,isString,fillLists,
    hasChinese,hasEnglish,onlyChinese,getByteLen,moreThanBytesLimitAndTtim,preloadImg,getBrowser,isGoogle,searchMainPermissionCheck,otherSearchPermittionCheck
,getSelfHeight,getSelfWidth,getProductByPath,checkUserCanUseProduct,setLocalStorage,getLocalStorage,deleteLocalStorage,getValFromObj,
    listSortDesc,listSortAes,scrollTop,splitArrayAvgNum,isArry,isFullNewUser,charListSort,
    checkUserNameReg,checkUserPassReg,setCookie,delCookie,getUrlTK,
    urlEnCode,urlDeCode,listUniqByfiled,debounce,listUniqFiled,isNewUserOrHonor,md5Text,
    geoCliParamParse(geoCliDataObj,notTip) {


        return new Promise((resolve, reject) => {

            let list = getValFromObj(geoCliDataObj, 'list')
            list = list.filter(it => {
                return it['active']
            })
            let resList = []
            let sampleCount=0
            let sampleTableTitle=[]
            let geoCliMd5=''
            let geoCliMd5Id=''
            let geoNumAndPlatforms=[]

            list.forEach(it => {
                let cliSonsObj=it['cliSons']
                let cliSons=[]
                geoCliMd5=it['geoCliMd5']
                geoCliMd5Id=it['id']

                for(let key in cliSonsObj){
                    cliSons.push(cliSonsObj[key])
                }
                cliSons = cliSons.filter(it => {
                    return it['active']
                })




                let geoNumMap={}
                cliSons.forEach(son=>{
                    //fileMd5
                    let sonList=getValFromObj(son,'list')
                    sonList.forEach((s)=>{
                        if(s['active']){
                            let fileMd5=getValFromObj(s,'fileMd5')
                            let sampleNum=getValFromObj(s,'sampleNum')
                            let geoNum=getValFromObj(s,'geoNum')
                            let platform=getValFromObj(s,'platform')
                            geoNumAndPlatforms.push({
                                geoNum,platform
                            })


                            if(varNotNull(fileMd5)){
                                sampleCount+=sampleNum
                                //console.log(s,fileMd5)
                                resList.push(geoNum+"_"+fileMd5)

                                if(! (geoNum in geoNumMap)){
                                    geoNumMap[geoNum]=[]
                                }
                                geoNumMap[geoNum].push(s)
                            }
                        }

                    })

                })

                let geoNumMapKeys=Object.keys(geoNumMap);

                if(geoNumMapKeys.length>1){

                    for(let key in geoNumMap){
                        sampleTableTitle.push(key)
                    }

                }else{
                    try {

                        geoNumMap[geoNumMapKeys[0]].forEach(it=>{
                            sampleTableTitle.push(it['name'])
                        })

                    }catch (e) {

                    }
                }



            })

            if(resList.length<=0 ){

                if(notTip){
                    return
                }

                window.globalMethods.swichTip(true, 'e', '请先选择GEO数据中的样本。', () => {
                    },
                    () => {

                    }, null)
                reject()
                return
            }
            console.log(resList,sampleCount,sampleTableTitle)

            resolve({
                resList:resList.join(","),
                sampleCount,
                sampleTableTitles:sampleTableTitle,geoCliMd5,geoCliMd5Id,geoNumAndPlatforms
            })

        })


    },
    clearGeoCliParamParse(geoCliDataObj,allNoActive) {


        return new Promise((resolve, reject) => {
            geoCliDataObj=cpObj(geoCliDataObj)
            let list = getValFromObj(geoCliDataObj, 'list')
            list = list.filter(it => {
                return it['active']
            })



            list.forEach(it => {

                let cliSonsObj=it['cliSons']
                let cliSons=[]
                for(let key in cliSonsObj){
                    cliSons.push(cliSonsObj[key])
                }
                cliSons = cliSons.filter(it => {
                    return it['active']
                })
                cliSons.forEach(son=>{
                    //fileMd5
                    let sonList=getValFromObj(son,'list')
                    sonList.forEach((s)=>{
                        if(s['active']){
                            s['active']=false
                        }
                    })

                })
            })



            resolve(geoCliDataObj)

        })


    },
    geoMyCliParse(geoMyCliData,notTip){


        return new Promise((resolve, reject) => {


            let goups=[]
            let goupsCountNum=0
            let dataFile=''
            let geoNumAndPlatforms=[]

            try {
                let list=geoMyCliData['list'].filter(it=>{
                    return it['active']
                })
                let activeItem=list[0];


                let datas=activeItem['data']['goups']
                dataFile=activeItem['dataFile']
                try {
                    geoNumAndPlatforms=JSON.parse(activeItem['geoNumAndPlatforms'])
                }catch (e) {

                }

                datas= datas.filter(it=>{
                    return it['active']
                })

                datas.forEach(it=>{
                    try {
                        goupsCountNum+=parseInt(it['count'].replace("(","").replace(")","").trim())
                    }catch (e) {

                    }

                    goups.push(it['goup'])
                })

            }catch (e) {

            }

            debugger


            if(goups.length<=0 || dataFile==''){


                if(notTip){
                    return
                }

                debugger

                window.globalMethods.swichTip(true, 'e', '请先选择GEO数据中的样本。', () => {
                    },
                    () => {

                    }, null)
                reject()
                return
            }

            let res={
                goups,
                goupsCountNum,
                dataFile,
                geoNumAndPlatforms
            }



            resolve(res)

        })

    },
    listGetCommonItem(arr){
        // let arr = [
        //     [1, 2, 3, 4],
        //     [3, 4, 6],
        //     [4, 5],
        //     [4, 5, 8, 9],
        //     [4, 5, 2, 7],
        //     [4, 5, 3],
        //     [4, 5, 0],
        // ];
        debugger
        let res=arr.reduce((a, b) => a.filter(c => b.includes(c))) // [4]

        return  res
    },
    downloadFile(url,fileName){
        // let href = `${url}`
        // let a = document.createElement('a')
        // a.setAttribute('download', '')
        // a.setAttribute('href', href)
        // a.setAttribute("target",'_blank')
        // a.click()
        if(varIsNull(fileName)){
            try {
                let parts=url.split("/")
                fileName=parts[parts.length-1]
            }catch (e) {
                fileName=''
            }

        }

        console.log('下载文件',fileName,url)
        let httpRequest = new XMLHttpRequest();
        //指定响应类型，这决定了浏览器对返回内容的解析方式，设置为空或者text会作为字符解析、json会作为json解析，blob和arraybuffer会作为字节流解析
        httpRequest.responseType ='arraybuffer';
        httpRequest.open('GET', url, true);
        httpRequest.onload  = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                //只有responseType为空或者text，才会使用responseText获取内容，其他情况                        httpRequest.response就是你需要的不含http头的返回内容
                let content = httpRequest.response;
                let elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                let blob = new Blob([content]);
                //创建指向内存中字节流的链接
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            }
        };
        httpRequest.send();
    },

    getFile(url,fileName){
        let httpRequest = new XMLHttpRequest();
        //指定响应类型，这决定了浏览器对返回内容的解析方式，设置为空或者text会作为字符解析、json会作为json解析，blob和arraybuffer会作为字节流解析
        httpRequest.responseType ='arraybuffer';
        httpRequest.open('GET', url, true);
        httpRequest.onload  = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                //只有responseType为空或者text，才会使用responseText获取内容，其他情况                        httpRequest.response就是你需要的不含http头的返回内容
                let content = httpRequest.response;
                let elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                let blob = new Blob([content]);
                //创建指向内存中字节流的链接
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                document.body.removeChild(elink);
            }
        };
        httpRequest.send();
    }





}
