import ajax from "./common/ajax";
import config from "./common/config";
import utils from "./common/utils";
import sciStore from "../store/modules/sciStore";
// import researchMyCliApi from "./researchMyCliApi";

let serverBase=config.serverBase

let attrObj={
  picCodeAddr: serverBase+'/public/user/vcode',
  phoneCodeAddr:''
}


function freeCounts(){

  let path="/public/research_assis/free_counts";

  let data={

  }

  return new Promise((resolve, reject) => {
    ajax.GET(serverBase+path,data,(res)=>{

        resolve(res)

      },
      (e)=>{
        reject(e)
      },null,false)
  })


}

function  getUserInfoVo(){
  let path="/public/user/get_user_info";

  let data={

  }

  return new Promise((resolve, reject) => {
    ajax.GET(serverBase+path,data,(res)=>{
        resolve(res)
      },
      (e)=>{
      if(e.userName){
        resolve(e)
      }else{
        try {
          reject(e)
        }catch (e){

        }

      }
      },null,false)
  })

}
export default {
  freeCounts,
  //获得用户信息
  getUserInfoVo,
  attrObj,

  //没用到的 老版本的微信开放平台的接口
  getWxAuth2LoginUrl:function (success,error) {

    let path='/protect/pub/auth2/login/wx'
    let url=serverBase+path
    let datas={ }

    ajax.GET(url,null,(res)=>{
        if (success){
          success(res)
        }

    },
    (res)=>{
      if (error){
        error(res)
      }
    })


  },
  auth2LoginCallBack:function (code,state,success,error) {


    let path='/public/auth2/login/wx/callback'
    let url=serverBase+path
    let datas={
      code,state
    }

    ajax.GET(url,datas,(res)=>{
        if (success){

          if(res.type){
            if(res.type!=''){
              if(res.type=='error'){
                res.type='e'
              }else{
                res.type='s'
              }
            }else{
              res['type']='e'
            }
          }else{
            res['type']='e'
          }

          success(res)
        }

      },
      (res)=>{
        if (error){
          error(res)
        }
      })
  },
  connectUserInfoCheckPhoneCode(state,phone,pass,phoneVcode,success,error){
    let path='/public/auth2/login/wx/connect'
    let url=serverBase+path
    pass=utils.md5PassWd(pass)
    let datas={
      state,phone,pass,phoneVcode
    }

    ajax.POST(url,datas,(res)=>{
        if (success){

          if(res.type){
            if(res.type!=''){
              if(res.type=='error'){
                res.type='e'
              }else{
                res.type='s'
              }
            }else{
              res['type']='e'
            }
          }else{
            res['type']='e'
          }

          success(res)
        }

      },
      (res)=>{
        if (error){
          error(res)
        }
      })


  },
  checkWxConnect(success,error){
    let path='/protect/pub/auth2/login/wx/checkWxConnect'
    let url=serverBase+path
    let datas={ }

    ajax.GET(url,null,(res)=>{
        if (success){
          success(res)
        }

      },
      (res)=>{
        if (error){
          error(res)
        }
      })

  },


  //绑定老用户手机发送短信验证码  实际上是rbacuser controller里的接口
  connectUserInfoSendEmailCode(vcode,email,success,error){

    let path='/public/user/bind_email_send_email_code'
    let url=serverBase+path
    let datas={
      vcode,email
    }

    ajax.POST(url,datas,(res)=>{
          if (success){
            // if(res.status){
            //   res.type='s'
            // }else{
            //   res.type='e'
            // }
            success(res)
          }
        },
        (res)=>{
          if (error){
            error(res)
          }
        })


  },

  connectUserInfoSendPhoneCode(vcode,phone,success,error){

    let path='/public/user/bind_phone_send_phone_code'
    let url=serverBase+path
    let datas={
      vcode,phone
    }

    ajax.POST(url,datas,(res)=>{
        if (success){

          if(res.type){
            if(res.type!=''){
              if(res.type=='error'){
                res.type='e'
              }else{
                res.type='s'
              }
            }else{
              res['type']='e'
            }
          }else{
            res['type']='e'
          }

          success(res)
        }

      },
      (res)=>{
        if (error){
          error(res)
        }
      })


  },
  handleGetUsername(res,success,careUserInfo){
    getUserInfoVo()
    // getUserInfoVo().then(userInfoVo=>{
    //   window.globalMethods['setUserInfoVo'](userInfoVo)
    // })


    // researchMyCliApi.getMyClinicNum().then(()=>{
    //
    // })
    let userInfo=utils.cpObj(res);
    let userName=userInfo['userName'];
    let nickName=userInfo['nickName'];
    try {
      if(userName.length>12){
        userInfo['username']=nickName;
        userInfo['nickName']=username;

      }else{
        if(utils.varNotNull(userName) && utils.varNotNull(nickName) && nickName!=userName){
          userInfo['username']=nickName;
          userInfo['nickName']=username;
        }
      }
    }catch (e) {

    }
    userInfo['realUserName']=userName

    sciStore.state.userInfo=userInfo;

    if (success!=null){
      // console.log('获得用户信息: ',userInfo," ",careUserInfo)
      success(res)
    }
  },
  //获得用户信息
  getUsername(success,error,careUserInfo,tk){
    // console.log('获得用户信息api',careUserInfo)
    let path="/public/user/userInfo";
    let data={
    }
    if(utils.varNotNull(tk)){
      data['tk']=tk
    }
    ajax.POST(serverBase+path,data,(res)=>{
      this.handleGetUsername(res,success,careUserInfo)
    },(res) => {
      if(res&&res.tk){
        this.handleGetUsername(res,success,careUserInfo)
      }else{
        if (error!=null){
          error(res)
        }
      }
      // if(utils.objNotNull(res) && utils.varNotNull(res['data']) && ){
      //
      // }
    },null,null,careUserInfo)
  },
  //登出
  logout(success,error){
    ajax.GET(serverBase+"/public/user/logout",null,(res)=>{
      if (success!=null){
        success(res)
      }
    },(res) => {
      if(res){
        success(res)
      }else{
        if (error!=null){
          error(res)
        }
      }
    })
  },
  //登录
  login(username, password,success,error) {
    // console.log(password)
    password=utils.md5PassWd(password)
    // console.log(password)
    let loginUrl='/login'
    if(process.env.NODE_ENV=='dev'){
      loginUrl="http://localhost:8080/login"
    }else{
      loginUrl=serverBase+'/login'
    }
    console.log(loginUrl)
    ajax.POST(loginUrl,{
      username,password
    },(res)=>{
      console.log(res)
      if (success!=null){
        success(res)
      }
    },(res) => {
      if (error!=null){
        error(res)
      }

    })

  },
  loginByTk(tk,success,error){
    ajax.POST(serverBase+"/public/user/login_by_hr_tk",{'tk':tk},(res)=>{
      if (success!=null){
        success(res)
      }
    },(res) => {
      if(res){
        success(res)
      }else{
        if (error!=null){
          error(res)
        }
      }
    })
  },
//注册 发送手机验证码  与登录发送验证码不同  需要首先判断的是 用户是否已经存在
  registerSendPhoneCode(phone,picCode,success,error){

    let url=serverBase+'/public/user/register/send_phone_code';

    let data={
      phone,
      vcode:picCode
    };
    ajax.POST(url,data,(res)=>{


      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },

  register(userName,passwd,phoneCode,success,error){

    let url=serverBase+'/public/user/register'

    passwd=utils.md5PassWd(passwd)
    let data={
      userName,
      passwd,
      phoneVcode:phoneCode
    }

    ajax.POST(url,data,(res)=>{

      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },
  getWxPubAccountUrl(success,error){
    let path='/public/wxpub/account/wxlogin/geturl'
    let url=serverBase+path
    let datas={ }

    ajax.GET(url,null,(res)=>{
        if (success){
          success(res)
        }

      },
      (res)=>{
        if (error){
          error(res)
        }
      })

  },
  // checkTempQrState(ticket,success,error){
  //   let path='/public/wxpub/account/wxlogin/checkstate'
  //   let url=serverBase+path
  //   let datas={ticket }
  //
  //   ajax.POST(url,datas,(res)=>{
  //       if (success){
  //         success(res)
  //       }
  //
  //     },
  //     (res)=>{
  //       if (error){
  //         error(res)
  //       }
  //     })
  // },
  checkTempQrState(ticket,rec){

    let path='/public/wxpub/account/wxlogin/checkstate'
    let url=serverBase+path
    let datas={ticket }

    if(utils.varNotNull(rec) && rec>0){
      datas['rec']=rec
    }


    return new Promise(((resolve, reject) => {

      ajax.POST(url,datas,(res)=>{
            resolve(res)
        },
        (res)=>{
            reject(res)
        })

    }));
  },






  //重置密码发送短信
  resetpassSendPhoneCode(phone,picCode,success,error){

    let url=serverBase+'/public/user/resetpass/send_phone_code';

    let data={
      phone,
      vcode:picCode
    };
    ajax.POST(url,data,(res)=>{


      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },

  //重置密码
  resetPass(userName,passwd,phoneCode,success,error){

    let url=serverBase+'/public/user/resetpass'

    passwd=utils.md5PassWd(passwd)
    let data={
      userName,
      passwd,
      phoneVcode:phoneCode
    }

    ajax.POST(url,data,(res)=>{

      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },
  //邮箱注册
  regEmailUser(userName,passwd,vcode,success,error){
    let url=serverBase+'/public/user/register'
    passwd=utils.md5PassWd(passwd)
    let data={
      userName,
      passwd,
      vcode:vcode
    }

    ajax.POST(url,data,(res)=>{
      if (success!=null){
        success(res)
      }

    },(res) => {
      if (error!=null){
        error(res)
      }

    })
  },




  //老用户绑定手机
  bindPhone(userName,passwd,phoneCode,success,error){

    let url=serverBase+'/public/user/bind_phone'

    passwd=utils.md5PassWd(passwd)
    let data={
      userName,
      passwd,
      phoneVcode:phoneCode
    }

    ajax.POST(url,data,(res)=>{

      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },

  //老用户绑定手机前检查
  bindPhoneCheck(success,error){

    let url=serverBase+'/public/user/bind_phone/check'

    let data={
    }

    ajax.GET(url,data,(res)=>{

      if(res.type){
        if(res.type!=''){
          if(res.type=='error'){
            res.type='e'
          }else{
            res.type='s'
          }
        }else{
          res['type']='e'
        }
      }else{
        res['type']='e'
      }
      if (success!=null){
        success(res)
      }

    },(res) => {

      if (error!=null){
        error(res)
      }

    })
  },
}
