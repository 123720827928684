export const lang = {
    //公共
    document_title:'CAR index',
    common_confirm:'Confirm',
    common_confirm_pay:'Confirm',
    common_no_data:'No data detected',
    common_no_data_short:'None',
    common_msg_dialog_title:'Tips',
    common_msg_login_first:'please login first', // to confirm
    common_msg_not_allow_access_first:'The relevant permissions have not been activated, please contact',
    //首页
    common_global_tip_title:'Tips',
    common_global_tip_confirm_known:'Noticed',
    common_global_tip_confirm_ok:'ok',
    //顶部
    common_header_lang: "中文",
    common_header_login: "Login",
    common_header_quit: "Click to log out",
    common_header_ret_home: "Click to back home",
    common_header_ret: "back",
    common_header_login_prompt: "Login first for better function use",
    common_header_remain_num: "Residual number",
    common_header_pay: "Donation",
    common_header_exit: "Exit",

    //底部
    common_contact:'Contact us',
    common_email:'Email address:',
    common_phone:'Phone number:',
    common_links_title:'Links',

    //登录注册
    //公共
    common_loginbox_input_email:'Email address',
    common_loginbox_input_pass:'Password',
    common_loginbox_link_login_wechat:'微信登录',
    common_loginbox_link_reg:'Sign up',
    common_loginbox_link_find_pass:'Forgot password?',
    common_loginbox_link_login:'Log in',
    //登录
    common_loginbox_login_title:'Sign in',
    common_loginbox_login:'Sign in',
    //注册
    common_loginbox_reg_title:'Sign up by Email',
    common_loginbox_send_code:'Get code',
    common_loginbox_input_code:'Security code',
    common_loginbox_reg:'Sign up',
    //找回密码
    common_loginbox_reset_title:'Reset password',
    common_loginbox_input_new_pass:'New password',
    //错误提示
    common_loginbox_err_email:'Invalid Email address! Please try again.',
    common_loginbox_err_pass:'Invalid password! Your password must contain at least 6 characters.',
    common_loginbox_err_code:'Invalid code. The security code must contain 4 characters.',
    //消息提示
    common_loginbox_msg_login_succ:'Login successful',
    common_loginbox_msg_login_fail:'Login error. Try again.',
    common_loginbox_msg_send_limit:'Too many operations. Try again later.',
    common_loginbox_msg_reg_succ:'Successful registration. You may log in using your email address and password.',
    common_loginbox_msg_reset_succ:'Password reset successful.',
    common_loginbox_msg_reset_succ_login_fail:'Password reset successfully.  Auto-login failed, please try again manually.',
    common_loginbox_msg_reset_fail:'Password reset failed.',
    common_loginbox_msg_send_email_fail:'Sending failed.',
    common_loginbox_msg_send_email_succ:'Sent.',
    common_loginbox_msg_code_err:'Invalid security code. Four failed attempts, one attempt left.',
    common_loginbox_msg_code_err_no_num:'Incorrect security code.',
    common_loginbox_msg_login_gt_limit:'You have exceeded the maximum number of attempts for this action. Try again later.',
    common_loginbox_msg_login_user_in_black_list:'Your account has been locked.',
    common_loginbox_msg_login_ip_in_black_list:'Your IP has been locked.',
    common_loginbox_msg_user_pass_err:'Username/password is incorrect. Three more attempts left.',
    common_loginbox_msg_user_not_exist:'There is no user registered with that Email address. Check your Email address and try again later.',
    common_loginbox_msg_user_has_exist:'The account already exists. Please log in.',
    common_loginbox_msg_reg_fail:'Registration failed. Try again.',
    common_loginbox_msg_logout_succ:'Log out successful',
    //邮箱验证码
    common_email_titile:'Security code sent by JCar',
    common_email_content:'Your security code is {1}. The code will expire in 5 mins, please enter the code quickly for successful registration',

    pagination: {
        goto: 'Go to',
        pagesize: '/page',
        total_prefix: 'Total ',
        total_postfix:'',
        pageClassifier: ''
    },


    //结果部分
    figcheck_result_source_title:'Similar sources:',
    figcheck_result_source_title_no_result:'No matches',
    figcheck_result_ps_source_title:'Original image:',
    figcheck_result_ps_source_title_no_result:'No pictures with traces of ps were detected.',
    figcheck_result_detail_title:'Details:',
    figcheck_result_ps_detail_title:'Ps trace prediction: ',
    figcheck_result_detail_option_show_line:'Markers',
    figcheck_result_detail_option_show_line_intro:'Red lines connect similar characteristics between two images to indicate their correlation, such as copy, rotation, inversion, etc.',
    figcheck_result_detail_option_remove_label:'Clear vision',
    figcheck_result_detail_option_remove_label_intro:'Annotations around the picture (such as lettering, scales etc.) will not be included, removing the false positive interference.',
    figcheck_result_detail_option_ps:'PS traces',
    figcheck_result_detail_option_ps_intro:'The WB image in the file will be individually identified, and the type includes two image manipulations of "copy, removal". After an internal test of 1,000 samples, the true positive rate of the results is about 92.3%. ',
    figcheck_result_single_ps_pred_prefix: 'Suspected ',
    figcheck_result_single_ps_pred_text_add: 'replication',
    figcheck_result_single_ps_pred_text_remove: 'removal',
    figcheck_result_ps_no_wb:'There is no wb diagram in the result, so you cannot use ps query. ',
    figcheck_result_detail_prompt:'Tip: Although artificial intelligence algorithms greatly improve the efficiency in identifying images with duplications, the duplications linked by red lines as shown above do not always make sense, as there are some cases such as icons, fluorescence images (merge), scaled images or sporadic false-positive results, etc. Please interpret the results according to the actual situation. <a href="https://www.figcheck.com/readme?goto=ResultInterpretation" target="_blank" class="link" style="font-weight:bolder;color: #409eff;">Click to see "Result interpretation"</a>. ',
    figcheck_result_ps_detail_prompt:'The PS trace detection function has been tested on 1000 internal samples, and the true positive rate of the results is about 92.3%, that is, 92.3% of the highlighted areas above may be the type of image manipulation shown on the right. But there are still 7.7% false positives, please judge according to the actual situation. ',
    figcheck_result_down_pdf:'Download Report',
    figcheck_result_downing_pdf:'Preparing...',
    //pdf报告
    figcheck_result_pdf_filename:'FigCheck Originality Report',
    figcheck_result_pdf_processing:'Processing. Try again later',

    //jcar 列表页
    jcar_first_case_prompt:"Search by first letter",
    jcar_search_journal_name:"Journal",
    jcar_search_issn:"ISSN",
    // jcar_search_research_area:"研究方向",
    jcar_search_if:"IF",
    // jcar_search_sci_included:"SCI收录",
    // jcar_search_big_cat:"大类学科",
    // jcar_search_small_cat:"小类学科",
    jcar_search_part:"JCR Journal Ranking",
    jcar_search_unlimit:"Unlimited",
    // jcar_search_is_oa:"是否OA期刊",
    // jcar_search_result_sort:"结果排序",
    jcar_search_btn:"Search",
    jcar_search_result_first_letter_prefix:"Letter",
    jcar_search_result_first_letter_postfix:"There are all the journals at the beginning",
    jcar_table_col_issn:"ISSN",
    jcar_table_col_journal_name:"Journal",
    jcar_table_col_car_index:"CAR Index",
    jcar_table_col_car_index_explain:"Due to the different publication times of journals in the current year, the denominator of the CAR index calculation will lag behind, which makes the CAR index appear high. It is recommended to use the previous year's CAR index as a reference for journal selection.",
    jcar_table_col_car_index_growth_rate_not_applicable:"Not Applicable",
    jcar_table_col_car_index_growth_rate_explain:"The real-time growth rate of the CAR index refers to the proportion of real-time in the current year (2023) compared to the previous year (2022). Due to the current strategy of dynamically monitoring journals adopted by Clarivate, if the real-time growth rate exceeds 2, the risk of Clarivate warning journals “on hold” will greatly increase.",
    // jcar_table_col_sci_risk_rank:"De-list risk",
    jcar_table_col_sci_risk_rank:"CAR risk",
    // jcar_table_col_sci_risk_rank_explain:"The risk of journals being excluded from SCI by clarivate is predicted. The higher the CAR index, the greater the risk. If the index is greater than 10%, or if it is more than twice that of the previous year at any time (with more than 10 integrity exposure events), it will be considered high-risk.",
    // jcar_table_col_sci_risk_rank_explain:"CAR risk assesses the academic integrity risk of journals from two dimensions: the number of academic integrity incidents and the annual rate of change. Since academic integrity risk is only one of the factors for Clarivate Analytics to eliminate SCI journals, there is no direct relationship between academic integrity risk and whether the journal is eliminated. However, under the same conditions, it is recommended to choose journals with lower risk.",
    jcar_table_col_sci_risk_rank_explain:"CAR risk assesses the academic integrity risk of journals from two dimensions: the number of academic integrity incidents and the annual rate of change. Only when the jcar index exceeds 10% in any of the past two years，it will be labeled as High-risk. The growth rate of the jcar index represents a negative trend for the journal, but it is not yet sufficient for rating, so it is labeled as a \"Warning\". Since academic integrity risk is only one of the factors for Clarivate Analytics to eliminate SCI journals, there is no direct relationship between academic integrity risk and whether the journal is eliminated. However, under the same conditions, it is recommended to choose journals with lower risk.",
    jcar_table_col_sci_risk_rank_high_ifs_explain:"This mainly comes from Pubpeer's exposure of multiple articles in the journal, and the JCAR platform cannot accurately determine whether it is academic discussion or academic misconduct. Users need to confirm and click the button to view the details.",
    jcar_table_col_ifs:"IF",
    jcar_table_col_part:"JCR Ranking",
    jcar_table_col_part_postfix:"",
    // jcar_table_col_subject:"学科领域",
    // jcar_table_col_big_cat:"大类",
    // jcar_table_col_small_cat:"小类",
    // jcar_table_col_sci_included:"SCI收录",
    // jcar_table_col_is_oa:"是否OA",
    // jcar_table_col_employment_ratio:"录用比例",
    // jcar_table_col_review_cycle:"审稿周期",
    jcar_table_col_view_num:"Looked",
    jcar_top_20_focuesd_journals:"Top 20 Focused Journals",


    //详情页
    jcar_detail_title_postfix:"CAR Profile",
    jcar_detail_base_info:"Basic information", // to confirm
    jcar_detail_car_index:"CAR index",
    jcar_detail_car_index_real_time:"Real", // to confirm
    jcar_detail_delist_risk:"De-list risk",
    jcar_detail_last_ifs:"IF",
    jcar_detaill_updown:"Rise fall", // to confirm
    jcar_detail_none_ifs:"It's not officially announced.", // to confirm
    jcar_detail_last5year:"Recent 5 years", // to confirm
    jcar_detail_part:"CAS",
    jcar_detail_part_postfix:"",
    jcar_detail_Country:"Country/Region",
    jcar_detail_publisher:"Publisher",
    jcar_detail_journal_intro:"Journal Intro", // to confirm
    jcar_detail_offical_website:"Offical website",
    jcar_detail_submission_website:"Submission website",
    jcar_detail_intro_retraction:"Journals have the initiative to retract articles, which is beneficial for maintaining academic integrity. However, they also threaten the journals, as they are the result of the quality of journal articles being suspected. The journal with retractions in the year before much more than years earlier will attract more attention. The number and degree of retractions are weighted when calculating the CAR Index.",
    jcar_detail_intro_pubpeer:"Suspecious journal articles are not always problematic. However, articles exposed on platforms such as Pubpeer are usually dubious, presenting problems and confusion, which damage the academic reputation of the journals where the articles are published. The journal wiht argured articles in the year before much more than years earlier will attract more attention. The number of argured articles are weighted when calculating the CAR Index.",
    jcar_detail_intro_figcheck:"Image duplication is a growing concern. Since 2022, FigCheck, a tool developed for screening image duplication, has worked on screening images from journal articles from OA publishers in real time. Moreover, secondary manual reviews are performed to eliminate potential false positives for confirmation. Image duplication is detrimental to the reliability of the research data and, therefore, to the academic integrity of journal. Since FigCheck is real-time, its examination results can better reflect the recent integrity profile of journals. The number of duplicated images found by FigCheck is also weighted when calculating the CAR Index.",
    jcar_detail_intro_reference_author:"Great importance should be attached to academic integrity risks involved in literature, including authors who may be involved in the publication of other problematic literature and references that have academic integrity issues. These may jeopardize the academic integrity of the literature and the corresponding journal. However, due to the 'misjudgment' caused by data format, the two are temporarily not included in the calculation weight of the CAR index.",
    jcar_detail_intro_share_email:"It is usually a feature of paper mills that the Email of the Corresponding Author is shared by other authors from different institutions. We will note this issue, and self-judgment is required. This data is not included in the calculation of the CAR index.",
    jcar_detail_intro_share_email_item_prompt:"*It is usually a feature of paper mills that the Email of the Corresponding Author is shared by two authors from different institutions. The above results are statistical results of big data and do not necessarily mean the articles are problematic. There are some conditions, such as authors changing to another unit for work, one Email shared by the members of the same research group, and different forms of the authors or institutions. In this context, self-judgment is required.",
    jcar_detail_intro_org_warning:"Being labeled as suspicious journals or not recommended for submission by any institutions in China considerably wrecks the reputation and academic integrity of the journals. Those data are derived from China only and not included in the calculation of the CAR index. Such evaluation by institutions, to some extent, often is based on the objective response of the academic community to the quality and reputation of journals.",
    jcar_detail_intro_in_scope:"The acceptance and publication of a large number of articles by journals that do not match the scope of the journal are often characteristics of predatory journals and have also received significant attention from Clarivate. However, the current data is based on AI judgment. Cross disciplinary articles require experts from relevant academic committees to make judgments. This data is currently not included in the calculation of the CAR index, but it is of great significance.",
    jcar_detail_title_retraction:"Annual distribution of retractions",
    jcar_detail_title_pubpeer:"Suspicions/Exposed articles",
    jcar_detail_title_figcheck:"Annual distribution of suspected articles detected by FigCheck",
    jcar_detail_title_scope:"Annual distribution of articles out of scope.", // 待确认
    jcar_detail_title_reference:"Integrity risk of references",
    jcar_detail_title_author:"Integrity risk of author",
    jcar_detail_title_share_email:"Shared Email address of Corresponding Author",
    jcar_detail_title_org_warning:"Warning list",
    jcar_detail_retraction:"Retraction",
    jcar_detail_publication_data:"Publication Data",
    jcar_detail_problem_type:"Problem Type",
    jcar_detail_literature_type:"Literature type",
    jcar_detail_authors:"Authors",
    jcar_detail_institution:"Institution",
    jcar_detail_journal:"Journal ",
    jcar_detail_before:"before",
    jcar_detail_type_pubeer:"Pubpeer exposure",
    jcar_detail_type_figcheck:"FigCheck screening result",
    jcar_detail_focus_reference:"Focused references",
    jcar_detail_focus_author:"Focused author",
    jcar_detail_same_email_author:"Author using the same Email address ",
    jcar_detail_same_email_author_prompt:"Authors of the article share the same Email address with the authors from other institution",
    jcar_detail_org_issue_institution:"Issuing Institution",
    jcar_detail_org_warning:"Warning",
    jcar_detail_org_warning_low:"Low",
    jcar_detail_org_warning_middle:"Medium",
    jcar_detail_org_warning_high:"High",
    jcar_detail_org_origin:"Origin",
    jcar_detail_year:" year before",
    jcar_detail_month:" month before",
    jcar_detail_week:" week before",
    jcar_detail_day:" day before",
    jcar_detail_hour:" hour before",
    jcar_detail_minute:" minute before",
    jcar_detail_second:" second before",
    jcar_detail_view_all:"Details",
    jcar_detail_view_reset:"Reset",

    jcar_detail_btn_data_export:"export",

    jcar_detail_figcheck_link_to:"Link to Figcheck",
    jcar_detail_pubpeer_link_to:"Link to Pubpeer",

    jcar_detail_pubpeer_comment_title:"Anonymous Comment",
    jcar_detail_pubpeer_comment_prefix:" commented ",
    jcar_detail_pubpeer_comment_postfix:"",

    // 指数介绍
    jcar_footer_intr_btn:"About J-CAR Index",

    jcar_intro_text_1:"About J-CAR Index",
    jcar_intro_text_2:"There has been growing concern about academic integrity in recent years, and accordingly, the academic integrity risks of journals have gained much attention as well. Clarivate has officially announced that it has developed an AI tool, which can lock the journals at a risk of having academic integrity issues and when necessary, de-list them from SCI <a href='https://retractionwatch.com/2023/03/21/nearly-20-hindawi-journals-delisted-from-leading-index-amid-concerns-of-papermill-activity/' target='_blank' style='color:#0071BC'>(e.g., the 35 de-listed journals this year)</a>. Thus, a tool that can comprehensively monitor and evaluate the academic integrity risks of journals is warranted. It can benefit not only journals, such as helping optimize their publication policies timely, but also authors to help them choose journals wisely. CAR, which means Comprehensive Academic integrity Risk, is an index assessing the academic integrity risks of journals from different types of data. CAR index can be used as a tool for predicting the risk of being excluded by SCI，for publishers to monitor journals, for authors to select journals. ",
    jcar_intro_text_3:"",
    jcar_intro_text_4:"Types of data collected",
    jcar_intro_text_5:"Types of data we collect include basic journal information and academic integrity-related data: number of retracted articles for that year, number of articles exposed on platforms such as Pubpeer for that year, annual distribution of problematic articles detected by FigCheck, annual distribution of published articles that are beyond the scope of the journal, annual distribution of articles whose authors are involved in academic integrity issues, annual distribution of articles whose references are involved in academic integrity issues, annual distribution of articles whose Email of the Corresponding Author is shared by two authors from different institutions, and whether the journal is labeled as a suspicious journal or not recommended for submission by any institutions in China.",
    jcar_intro_text_6:"How J-CAR index calculated ",
    jcar_intro_text_7:"J-CAR Index = (The number of retracted articles + the number of articles exposed on platforms such as Pubpeer + the number of problematic articles detected by FigCheck) /the number of published articles of the journal for that year.",
    jcar_intro_text_8:"Every March, J-CAR releases the index for that year. The J-CAR index is a ratio to estimate the relative risk of having academic integrity issues. A higher ratio indicates a higher risk of having academic integrity issues regarding the articles published for that year. As the numbers of exposed articles and published articles for that year are inconsistent, the J-CAR index can be larger than 1. Because the number of publications and problematic literature in journals will dynamically change, the CAR index will also undergo dynamic changes, especially in the current year's CAR index. If there were no published articles in the journal that year but there were academic integrity events, the CAR index would be in the form of R+P+F/N. For example, if there were 7 academic integrity events but no published articles that year, it would be 7/0.",
    jcar_intro_text_9:'Among the 32 journals that were recently de-listed from SCI by Clarivate (3 journals had stopped publication in all 35 excluded journals), 19 journals had a J-CAR index for 2022 or a real-time CAR index for 2023 exceeding 10%. Thus, we set 10% as a threshold for a high risk of being de-listed from SCI（Low-risk < 5%, Medium-risk 5-10% ）. Notably, we noticed that the J-CAR indexes for 2022 or the real-time J-CAR indexes for 2023 of all the 32 journals, except REVISTA BRASILEIRA DE MEDICINA DO ESPORTE, were greater than 10%, when "Whether the published article is out of the scope of the journal" was considered. However, This parameter is not included in the calculation of the J-CAR index because of the existence of some across subjects and a necessity of assessment by an expert committee. In all, it can also provide reference for our users. ',
    jcar_intro_text_9_bottom_intro:"Since all data are dynamic, the online version shall prevail.",
    jcar_intro_text_10:"J-CAR index of the 35 de-listed journals",

    jcar_intro_text_101:"Risk rating rules",
    jcar_intro_text_102:"",
    jcar_intro_text_103:"1. If the jcar index of the previous year or current year is greater than 10%, it is marked as High-risk, 5-10% is Medium risk, and less than 5% is Low risk.",
    jcar_intro_text_104:"2. The following situation indicates that the academic integrity risk of this journal is increasing, but it is not sufficient for rating. It will be labeled as a \"Warning\".",
    jcar_intro_text_105:"  If the ratio of the jcar index to the previous year is greater than 2, and the number of academic integrity events in that year is greater than 5, and less than 5 is low risk.",
    jcar_intro_text_106:"  If the Jcar index of the previous year was 0, the number of academic integrity events in next year greater than 5 and less than 5 is low risk.",

    // jcar_intro_text_102:"Based on the above risk rating rules, after optimization, the following risk setting rules have been formed:",
    // jcar_intro_text_103:"1. If the jcar index of the previous year or current year is greater than 10%, it is marked as high-risk, 5-10% is medium risk, and less than 5% is low risk.",
    // jcar_intro_text_104:"2. If the ratio of the jcar index to the previous year is greater than 2, and the number of academic integrity events in that year is greater than 10, it is considered high risk, 5-10 is medium risk, and less than 5 is low risk.",
    // jcar_intro_text_105:"3. If the Jcar index of the previous year was 0, then only the number of academic integrity events in that year will be considered. 0-5 represents low risk, 5-10 represents medium risk, and greater than 10 represents high risk.",
    // jcar_intro_text_106:"4. Different rules ultimately use the highest level of risk as the final risk level.",

    jcar_intro_text_11:"Subscription",
    jcar_intro_text_12:"Data on the J-CAR Index website is open for only publishers and editors of journals to see if there is an academic integrity risk. Users can contact us if they need more information about the journal they are interest in and then will be authorized. In general, it usually takes 3-7 days to improve the data of a certain journal as confirmation by manual review is required.",
    jcar_intro_text_13:"After authorization, users can check all types of data of the journal they are interest in, download related articles (exl.), and set conditions for alarming (e-mail). FigCheck imaging detection is real-time, and thus the data can be received by authors in time and then alarm the related journal.",
    jcar_intro_text_14:"API access is available, and publishers can use the J-CAR index by linking the website to their submission system to help assess the academic integrity risks of articles submitted, including text plagiarism (selectable), image duplication, authors involved in other problematic articles, references involved in other problematic articles, articles published beyond the scope of the journal, e-mail of corresponding author shared by authors from different institutions.",
    jcar_intro_text_15:"Limitation",
    jcar_intro_text_16:"The J-CAR Index reflects the academic integrity risks of journals. It is not entirely equal to the risk of being de-listed from SCI, although it has been confirmed <a href='https://retractionwatch.com/2023/03/21/nearly-20-hindawi-journals-delisted-from-leading-index-amid-concerns-of-papermill-activity/' target='_blank' style='color:#0071BC'>with the 35 journals de-listed from SCI by Clarivate.</a> For example, some journals are still on the list of SCI even with a J-CAR index of greater than 10%. This indicates that Clarivate de-list journals from SCI based on a comprehensive assessment, rather than on academic integrity risks alone.The records on Pubpeer do not necessarily indicate that journal articles are related to academic integrity issues, or may only be academic discussions. If a journal's CAR index is identified as high-risk due to high Pubpeer exposure, it will be marked with a \"<i class=\"el-icon-warning\" ></i>\", and users can directly click on the details to make their own judgments. Suggestions proposed by the J-CAR index are only for references, and any potential consequences are independent of the J-CAR Index website.",

    // car project
    jcar_project_title:"CAR Project Membership",
    jcar_project_sub_title_1:"CAR Project",
    jcar_project_text_1:"The issue of academic integrity of journals and papers has received extensive attention from the academic community in recent years. The CAR Project is a non-profit initiative launched by the JCAR-Index platform, hoping to maintain the academic integrity of journals/papers. We encourage institutions such as journals/publishers with the same philosophy to join us.",
    jcar_project_sub_title_2:"Benefits to our members",
    jcar_project_text_2_1:"1. We provide real-time data related to academic integrity (<a href='/intro' target='_blank' style='color: #266ce8;' >8 types</a>) for free and automatically send email reminders;",
    jcar_project_text_2_2:"2. We provide the MCAR system for free to evaluate the academic integrity risks of submitting manuscripts efficiently;",
    jcar_project_text_2_3:"3. Based on the current huge number of users (over 1.5 million users), we provide our members with personalized services free of charge, including Journal Reputation Questionnaire Report, etc.",
    jcar_project_sub_title_3:"Our Members",
    jcar_project_text_3_1:"Institutions including journals, publishers, etc.",
    jcar_project_sub_title_4:"Membership Fee",
    jcar_project_text_4_1:"No fee will be charged. ",
    jcar_project_sub_title_5:"Qualification and Application",
    jcar_project_text_5_1:"1. Prepare materials which can show that you have made some corresponding measures to maintain the academic integrity of journals/papers;",
    jcar_project_text_5_2:"2. Contact <b>admin@jcarindex.com</b>",
    jcar_project_sub_title_6:"Member logo",
    jcar_project_text_6_1:"Our members will be authorized to note “Member of CAR Project” on their official website or other medias.",
    jcar_project_text_down_logo:"Download high-definition logo",



}
