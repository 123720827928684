export const lang = {
    //公共
    document_title:'学术诚信风险指数',
    common_confirm:'确定',
    common_confirm_pay:'捐赠',
    common_no_data:'暂无数据',
    common_no_data_short:'暂无',
    common_msg_dialog_title:'温馨提示',
    common_msg_login_first:'请先登录',
    common_msg_not_allow_access_first:'尚未开通相关权限，请联系',
    //首页
    common_global_tip_title:'温馨提示',
    common_global_tip_confirm_known:'知道了',
    common_global_tip_confirm_ok:'好的',
    //顶部
    common_header_lang: "English",
    common_header_login: "机构登录",
    common_header_quit: "点击注销",
    common_header_ret_home: "点击返回首页",
    common_header_ret: "返回",
    common_header_login_prompt: "每日{0}次查询机会,请合理使用",
    common_header_remain_num: "剩余次数",
    common_header_pay: "捐赠",
    common_header_exit: "退出",

    //底部
    common_contact:'联系我们',
    common_email:'邮箱',
    common_phone:'电话',
    common_links_title:'友情链接',

    //登录注册
    //公共
    common_loginbox_input_email:'请输入邮箱',
    common_loginbox_input_pass:'请输入密码',
    common_loginbox_link_login_wechat:'微信登录',
    common_loginbox_link_reg:'点击注册',
    common_loginbox_link_find_pass:'找回密码',
    common_loginbox_link_login:'点击登录',
    //登录
    common_loginbox_login_title:'登录',
    common_loginbox_login:'登录',
    //注册
    common_loginbox_reg_title:'邮箱注册',
    common_loginbox_send_code:'发送验证码',
    common_loginbox_input_code:'请输入邮箱验证码',
    common_loginbox_reg:'注册',
    //找回密码
    common_loginbox_reset_title:'找回密码',
    common_loginbox_input_new_pass:'请输入新密码',
    // common_loginbox_confirm:'确认',
    //错误提示
    common_loginbox_err_email:'输入的邮箱格式不正确,请重新输入',
    common_loginbox_err_pass:'密码不合法,密码为6位以上数字字母组合',
    common_loginbox_err_code:'邮箱验证码不合法,验证码为4位字母或数字',
    //消息提示
    common_loginbox_msg_login_succ:'登录成功',
    common_loginbox_msg_login_fail:'登录失败,获取用户信息失败,请重试',
    common_loginbox_msg_send_limit:'发送邮件间隔限制,请稍等一会',
    common_loginbox_msg_reg_succ:'新用户邮箱注册成功,可以通过邮箱和密码进行登录',
    common_loginbox_msg_reset_succ:'密码重置成功,自动登录成功',
    common_loginbox_msg_reset_succ_login_fail:'密码重置成功,自动登录失败,请尝试手动登录',
    common_loginbox_msg_reset_fail:'密码重置失败,请重试',
    common_loginbox_msg_send_email_fail:'发送邮件失败',
    common_loginbox_msg_send_email_succ:'发送邮件成功',
    common_loginbox_msg_code_err:'验证码错误,错误次数已达{0}次,还能重试次数{1}次',
    common_loginbox_msg_code_err_no_num:'验证码错误',
    common_loginbox_msg_login_gt_limit:'登录错误超过限制,请过会再试',
    common_loginbox_msg_login_user_in_black_list:'你的账号已被锁定,暂时无法登录使用',
    common_loginbox_msg_login_ip_in_black_list:'你的IP已被锁定,暂时无法登录使用',
    common_loginbox_msg_user_pass_err:'账号或密码错误,还能重试{0}次',
    common_loginbox_msg_user_not_exist:'用户可能不存在,请先注册',
    common_loginbox_msg_user_has_exist:'用户已存在,请登陆',
    common_loginbox_msg_reg_fail:'账号注册失败,请重新注册',
    common_loginbox_msg_logout_succ:'注销成功',
    //邮箱验证码
    common_email_titile:'邮箱验证码 by JCar',
    common_email_content:'你好，请将以下验证码 0000 输入至注册页面输入框，完成注册，谢谢。',

    pagination: {
        goto: '前往',
        pagesize: '条/页',
        total_prefix: '共',
        total_postfix:'条',
        pageClassifier: '页'
    },


    //结果部分
    figcheck_result_source_title:'可能相似的图片对: ',
    figcheck_result_source_title_no_result:'没有发现相似图片。',
    figcheck_result_ps_source_title:'可能存在ps痕迹的原图: ',
    figcheck_result_ps_source_title_no_result:'没有检测到有ps痕迹的图片。',
    figcheck_result_detail_title:'细节对比: ',
    figcheck_result_ps_detail_title:'ps痕迹预测: ',
    figcheck_result_detail_option_show_line:'显示线',
    figcheck_result_detail_option_show_line_intro:'红线连接两张图片之间相似的特征值，帮助指示特征值之间相似的方式，如复制、旋转、反转等。',
    figcheck_result_detail_option_remove_label:'去标注',
    figcheck_result_detail_option_remove_label_intro:'图片边缘的标注部分（如图示等）将不会纳入特征值比较，去除图片标注导致的假阳性干扰。',
    figcheck_result_detail_option_ps:'PS痕迹',
    figcheck_result_detail_option_ps_intro:'对文件中的WB图进行单独识别，类型包含针对条带的“复制，去除”2种图片操纵，经内部1000个样本测试，结果的真阳性率约为92.3%。',
    figcheck_result_single_ps_pred_prefix: '疑似',
    figcheck_result_single_ps_pred_text_add: '复制',
    figcheck_result_single_ps_pred_text_remove: '去除',
    // 提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图等；一般，红线越聚集，重复的可能性越大。请根据实际情况判断。
    figcheck_result_ps_no_wb:'结果中无wb图,无法使用ps查询。',
    figcheck_result_detail_prompt:'温馨提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图、缩放、零星的假阳性等，请根据实际情况判断。<a href="https://www.figcheck.com/readme?goto=ResultInterpretation" target="_blank" class="link" style="font-weight: bolder;color: #409eff;">点击查看更详细的结果判定方法</a>。',
    figcheck_result_ps_detail_prompt:'PS痕迹检测功能经内部1000个样本测试，结果的真阳性率约为92.3%，即以上高亮区域有92.3%可能是进行了右侧所示的图片操纵类型。但仍有7.7%的假阳性，请根据实际情况判断。',
    figcheck_result_down_pdf:'下载报告',
    figcheck_result_downing_pdf:'生成中...',


    //jcar 列表页
    jcar_first_case_prompt:"按期刊名首写字母查看",
    jcar_search_journal_name:"期刊名",
    jcar_search_issn:"ISSN",
    // jcar_search_research_area:"研究方向",
    jcar_search_if:"IF",
    // jcar_search_sci_included:"SCI收录",
    // jcar_search_big_cat:"大类学科",
    // jcar_search_small_cat:"小类学科",
    jcar_search_part:"中科院分区",
    jcar_search_unlimit:"不限",
    // jcar_search_is_oa:"是否OA期刊",
    // jcar_search_result_sort:"结果排序",
    jcar_search_btn:"搜索",
    jcar_search_result_first_letter_prefix:"字母",
    jcar_search_result_first_letter_postfix:"开头的期刊共有",
    jcar_table_col_issn:"ISSN",
    jcar_table_col_journal_name:"期刊名",
    jcar_table_col_car_index:"CAR 指数",
    jcar_table_col_car_index_explain:"CAR指数是期刊当年涉及学术诚信风险的文章数相对于当年发表量的比值，越高代表风险越大。",
    jcar_table_col_car_index_growth_rate_not_applicable:"无",
    jcar_table_col_car_index_growth_rate_explain:"CAR指数实时增速指的是当年（2023年）相比上一年度（2022年）的实时占比，由于科睿唯安目前采取动态监控期刊的策略，如果实时增速大于2，科睿唯安将期刊警示（on hold）的风险将大增。",
    jcar_table_col_sci_risk_rank:"被踢风险",
    jcar_table_col_sci_risk_rank_explain:"被踢风险是根据CAR指数高低，预测出的期刊被科睿唯安官方剔除SCI的风险分级，CAR指数越高，风险越大。",
    jcar_table_col_sci_risk_rank_high_ifs_explain:"这主要来自Pubpeer对该期刊多个文章的曝光，平台无法准确无误判断是学术讨论还是学术不端，需要用户确认，点击按钮可查看详情。",
    jcar_table_col_ifs:"影响因子",
    jcar_table_col_part:"中科院分区",
    jcar_table_col_part_postfix:"区",
    // jcar_table_col_subject:"学科领域",
    // jcar_table_col_big_cat:"大类",
    // jcar_table_col_small_cat:"小类",
    // jcar_table_col_sci_included:"SCI收录",
    // jcar_table_col_is_oa:"是否OA",
    // jcar_table_col_employment_ratio:"录用比例",
    // jcar_table_col_review_cycle:"审稿周期",
    jcar_table_col_view_num:"查看数",
    jcar_top_20_focuesd_journals:"最值得谨慎投稿的20个诚信风险期刊",

    //详情页
    jcar_detail_title_postfix:"学术诚信风险概览",
    jcar_detail_base_info:"基本信息",  //  待确认
    jcar_detail_car_index:"CAR指数",
    jcar_detail_car_index_real_time:"实时",
    jcar_detail_delist_risk:"被踢风险",
    jcar_detail_last_ifs:"最新影响因子",
    jcar_detail_updown:"涨跌",
    jcar_detail_none_ifs:"官方未公布",
    jcar_detail_last5year:"近5年",
    jcar_detail_part:"中科院",
    jcar_detail_part_postfix:"区",
    jcar_detail_Country:"国家",
    jcar_detail_publisher:"出版社",
    jcar_detail_journal_intro:"期刊简介",
    jcar_detail_offical_website:"期刊官方网站",
    jcar_detail_submission_website:"期刊投稿网址",
    jcar_detail_intro_retraction:"文章撤稿是期刊的主动行为，从维护学术诚信的角度来说是正面的。但显然也是代表期刊文章的质量问题受到了质疑，因此对期刊学术诚信造成损害。最近一年撤稿数量明显比之前增加的期刊将被重点关注。撤稿数量和幅度纳入CAR指数的计算权重中。",
    jcar_detail_intro_pubpeer:"期刊上的文章被其他学者质疑和曝光，并不一定代表该文章有问题，但例如Pubpeer等平台的曝光，通常代表该文章出现了困惑和问题，因此对期刊的学术诚信造成损害。最近一年质疑数量明显比之前增加的期刊将被重点关注。被质疑的文章数量纳入CAR指数的计算权重中。",
    jcar_detail_intro_figcheck:"图片重复问题越来越受到关注。自2022年开始，Flecheck系统实时监测OA的期刊文章，为了消除可能的假阳性，经过学术编辑团队进行人工二次审核后，确认图片重复问题。图片重复使用问题，将对文章的数据可靠性产生损害，因此对期刊学术诚信造成损害。由于该检测是实时的，所以其更能反应该期刊最近的诚信概况，Flgcheck图片重复数量纳入CAR指数的计算权重中。",
    jcar_detail_intro_reference_author:"期刊上的文献所涉及的学术诚信风险值得关注，包括某一作者可能涉及其他问题文献的发表，参考文献涉及学术诚信问题，都会增加该文献和对应期刊的学术诚信风险。但由于存在因为数据格式造成的“误判”，两者暂不纳入CAR指数的计算权重中。",
    jcar_detail_intro_share_email:"文章某一作者存在和其他机构不同作者共用通讯邮箱的情况，这通常是论文工厂的特征，但由于作者标注和机构标注的多样性，我们在此只作为标注，由使用者自行判断。该数据不纳入CAR指数的计算权重中。",
    jcar_detail_intro_share_email_item_prompt:"*不同机构作者使用同一邮箱投稿，是'论文工厂'的特征之一。上述结果为大数据统计结果，不代表一定涉及，比如作者换单位，或者同一课题组统一使用某邮箱作为通讯邮箱，或者因为作者名字、机构的不同写法等，请自行判断。",
    jcar_detail_intro_org_warning:"期刊被中国任何机构设定为不建议投稿名单或者预警期刊，极大损害期刊的声誉和学术诚信。该数据仅来着中国，不纳入CAR指数的计算权重中。但往往机构的评估一定程度上，是基于学术界对期刊质量和声誉的客观反应。",
    jcar_detail_intro_in_scope:"期刊接收发表大量和期刊接收范围不符的文章，往往是掠夺性期刊的特征，也受到科睿唯安的重点关注。这一数据被纳入CAR指数的计算当中。",
    jcar_detail_title_retraction:"期刊撤稿概况年度分布",
    jcar_detail_title_pubpeer:"文章被质疑/曝光概况",
    jcar_detail_title_figcheck:"Figcheck图片查重实时数据文章年度分布",
    jcar_detail_title_scope:"不在收稿范围",
    jcar_detail_title_reference:"参考文献诚信风险",
    jcar_detail_title_author:"文章作者诚信风险",
    jcar_detail_title_share_email:"共用通讯邮箱情况",
    jcar_detail_title_org_warning:"中国机构警示概况",
    jcar_detail_retraction:"撤稿",
    jcar_detail_publication_data:"发布日期",
    jcar_detail_problem_type:"问题类型",
    jcar_detail_literature_type:"文献类型",
    jcar_detail_authors:"作者",
    jcar_detail_institution:"机构",
    jcar_detail_journal:"期刊",
    jcar_detail_before:"前",
    jcar_detail_type_pubeer:"pubpeer曝光",
    jcar_detail_type_figcheck:"figcheck筛查",
    jcar_detail_focus_reference:"关注参考文献",
    jcar_detail_focus_author:"关注作者",
    jcar_detail_same_email_author:"共用人",
    jcar_detail_same_email_author_prompt:"该文献有以下作者，和非同机构的其他作者共用邮箱",
    jcar_detail_org_issue_institution:"发布机构",
    jcar_detail_org_warning:"警示等级",
    jcar_detail_org_warning_low:"低",
    jcar_detail_org_warning_middle:"中",
    jcar_detail_org_warning_high:"高",
    jcar_detail_org_origin:"来源标题",
    jcar_detail_year:"年前",
    jcar_detail_month:"月前",
    jcar_detail_week:"周前",
    jcar_detail_day:"天前",
    jcar_detail_hour:"小时前",
    jcar_detail_minute:"分钟前",
    jcar_detail_second:"秒前",
    jcar_detail_view_all:"查看全部",
    jcar_detail_view_reset:"重置",

    jcar_detail_btn_data_export:"导出",

    jcar_detail_figcheck_link_to:"Figcheck详情",
    jcar_detail_pubpeer_link_to:"Pubpeer详情",

    jcar_detail_pubpeer_comment_title:"匿名评价",
    jcar_detail_pubpeer_comment_prefix:"于",
    jcar_detail_pubpeer_comment_postfix:"评论",

    // 指数介绍
    jcar_footer_intr_btn:"期刊CAR指数介绍",

    jcar_intro_text_1:"什么是期刊CAR指数平台？",
    jcar_intro_text_2:"近年来，随着科研界对学术诚信问题的关注，期刊的学术诚信风险也受到广泛关注。根据科睿唯安官方的说法，其内部已经开发了AI工具，来锁定风险期刊，必要的时候将被剔除SCI数据库，比如今年一次性35本期刊的剔除。因此对于期刊出版社或者作者，需要一个全面监控、评价期刊学术诚信风险的工具，来指导出版社及时优化自己的出版政策，协助作者更好的选择期刊。",
    jcar_intro_text_3:"CAR是Comprehensive Academic integrity Risks的简写，意为通过多个类型数据对期刊的学术诚信风险进行一个全面的评估。期刊CAR指数平台（jcarindex.com）是一个评价SCI期刊学术诚信风险（CAR）的数据平台，由注册在中国杭州的一家创新型公司Home for researchers开发维护，其自2018年成立以来，一直致力于中国科研人员科研效率的提升。",
    jcar_intro_text_4:"期刊CAR指数平台汇总哪些数据？",
    jcar_intro_text_5:"期刊CAR指数平台包含期刊的基本信息，和如下和学术诚信相关的数据：当年被撤稿文章数量，当年被Pubpeer等同行评议平台曝光的文章数量，被Figcheck图片查重系统发现图片重复的文章年度分布、超出期刊收稿范围的文章年度分布，作者涉及学术诚信问题的文章年度分布，参考文献涉及学术诚信问题的文章年度分布，不同机构不同作者共用通讯邮箱的文章年度分布，被中国机构纳入黑名单的情况。",
    jcar_intro_text_6:"期刊CAR指数如何计算？",
    jcar_intro_text_7:"当年被撤稿文章的文章数+当年被Pubpeer等曝光的文章数+当年被Figcheck发现图片重复的文章数+当年超出期刊收稿范围的文章数/期刊当年发文量。",
    jcar_intro_text_8:"因此期刊CAR指数是一个相对风险比值，比值越高代表该期刊当年的学术诚信风险相对于当年发表文章数来说比值越高。由于当年被曝光学术诚信风险的文章和当年发表的文章并不一致，因此CAR指数可以大于1。",
    jcar_intro_text_9:"在32本最近被科睿唯安剔除的SCI期刊中（3本期刊因为停刊而被剔除），19本期刊的2022年度CAR指数或2023年实时CAR指数超过10%，因此10%被设定为高风险（期刊被剔除的风险）的截止。值得注意的是，当将CAR指数纳入文章是否超出期刊Scope的因素后，除了REVISTA BRASILEIRA DE MEDICINA DO ESPORTE，其他31本期刊的2022年CAR指数或2023年实时CAR指数都大于10%。因此，虽然考虑到交叉学科等一些原因，文章是否符合期刊scope需要经过专家委员会的评估，因此\"out of scope\"并没有被纳入CAR指数的计算，但基于人工智能的初步判定可以给用户提供参考。",
    jcar_intro_text_9_bottom_intro:"由于数据是动态录入的，可能出现变化，请以在线版本为准。",
    jcar_intro_text_10:"35本期刊CAR指数数据",

    jcar_intro_text_101:"Risk rating rules",
    jcar_intro_text_102:"Based on the above risk rating rules, after optimization, the following risk setting rules have been formed:",
    jcar_intro_text_103:"1.If the jcar index of the previous year or current year is greater than 10%, it is marked as high-risk, 5-10% is medium risk, and less than 5% is low risk.",
    jcar_intro_text_104:"2.If the ratio of the jcar index to the previous year is greater than 2, and the number of academic integrity events in that year is greater than 10, it is considered high risk, 5-10 is medium risk, and less than 5 is low risk.",
    jcar_intro_text_105:"3.If the Jcar index of the previous year was 0, then only the number of academic integrity events in that year will be considered. 0-5 represents low risk, 5-10 represents medium risk, and greater than 10 represents high risk.",
    jcar_intro_text_106:"4. Different rules ultimately use the highest level of risk as the final risk level.",

    jcar_intro_text_11:"机构如何订阅期刊CAR指数？",
    jcar_intro_text_12:"CAR指数平台的数据不对外开发，只针对出版社，期刊编辑等机构用户内部监控期刊学术诚信风险使用。对某个期刊完整数据有需求的用户，可以联系我们开通权限。由于需要人工二次确认，一般来说，某个期刊的数据整理需要3-7天时间。",
    jcar_intro_text_13:"权限开通后，机构用户登录后可以查看该期刊的各项数据、以表格形式下载相关文章、设置邮箱预警条件及时通知，对于Figcheck图片查重数据，由于其是实时监控的结果，因此可以及时通知相关作者，联系出版社勘误。",
    jcar_intro_text_14:"出版社也可以申请将CAR指数接口接入稿件提交系统，对稿件进行全面的学术诚信风险评估，内容包括：文字重复率（可选），图片重复情况、作者是否涉及其他学术诚信文章，参考文献是否涉及学术诚信问题文章，稿件是否符合期刊收稿范围，通讯邮箱和其他机构的他人有重叠的情况。",
    jcar_intro_text_15:"期刊CAR指数的局限性？",
    jcar_intro_text_16:"期刊CAR指数风险评估的是期刊的学术诚信风险，虽然在最近35本被科睿唯安剔除的期刊上得到了印证，但不代表CAR指数就一定能预测期刊被剔除的风险。根据CAR指数的建议投稿或者不投稿产生的潜在结果，与CAR指数平台无关。",

    // car project
    jcar_project_title:"CAR Project Membership",
    jcar_project_sub_title_1:"CAR Project",
    jcar_project_text_1:"期刊和论文学术诚信问题近年来受到学术界的广泛关注。CAR Project是CAR指数平台推出的一项维护学术界期刊/论文学术诚信的公益倡议。CAR Project招募有相同理念的期刊/出版社等机构加入。",
    jcar_project_sub_title_2:"会员优势",
    jcar_project_text_2_1:"1. CAR指数平台将无偿为会员提供8大类型的有关期刊学术诚信的实时监控数据，以及邮件提醒服务；",
    jcar_project_text_2_2:"2. 无偿提供的稿件学术诚信评估系统（MCAR系统）；",
    jcar_project_text_2_3:"3. CAR指数平台基于目前庞大的用户量（超过150万），无偿为会员提供力所能及的期刊诚信数据方面的个性化服务，如期刊声誉调研问卷报告等。",
    jcar_project_sub_title_3:"会员对象",
    jcar_project_text_3_1:"目前只针对期刊/出版社等机构开放，不向个人开放",
    jcar_project_sub_title_4:"会员费用",
    jcar_project_text_4_1:"CAR Project和CAR指数平台一样，都是公益项目，不会收取任何费用。",
    jcar_project_sub_title_5:"会员资格审核和申请",
    jcar_project_text_5_1:"1. 请准备材料说明已有相应措施来维护期刊/论文的学术诚信完整性",
    jcar_project_text_5_2:"2. 请联系 admin@jcarindex.com",
    jcar_project_sub_title_6:"Member logo",
    jcar_project_text_6_1:"会员将被授权在其官网或宣传媒介使用Member of CAR Project的标识，如下：",
    jcar_project_text_down_logo:"Download high-definition logo",

}


